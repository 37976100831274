<template>
  <div>
    <div class="card p-3 mb-3 shadow-sm">
      <div class="row no-gutters">
        <div class="col auto">
          <h5 class="e-text-red">
            Non-Conformity / Observation Note / Opportunity For Improvement
          </h5>
        </div>
        <div class="col-md-auto">
          <label class="col-form-label font-weight-bold">Filter By Type: </label>
        </div>
        <div class="col-md-auto px-1">
          <select name="type" id="type" class="form-control form-control-sm text-uppercase" @change="setNcrType" v-model="selectedNcrType">
            <option :value="'ncr'">NCR</option>
            <option :value="'obs'">OBS</option>
            <option :value="'improvement'">IMPROVEMENT</option>
          </select>
        </div>
        <div class="col-md-auto text-right">
          <button class="e-btn e-btn-green mb-2 px-4 e-text-white mx-2" data-toggle="modal" data-target="#newNcrObs" @click="showAddNcrModal = true">
            <font-awesome-icon icon="plus" class="mr-2"/>
            Add NCR/OBS
          </button>
        </div>
      </div>
      <table class="table table-sm table-bordered text-center">
        <thead>
        <tr>
          <th>#</th>
          <th style="width: 30%">NCR/OBS Description</th>
          <th style="width: 25%">Root Causes</th>
          <th>Training Need</th>
          <th>Change of Procedures</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr class="text-uppercase" v-for="(nonConformity, index) in companyNonConformities[$route.params.type]" :class="{ 'e-bg-blue-light-8' : nonConformity.id === parseInt($route.params.ncr_id) }">
          <td class="align-middle"> {{ index + 1 }} </td>
          <td class="align-middle text-left"> {{ nonConformity.description | stripHtmlTags }} </td>
          <td class="align-middle pt-1 pb-1" style="padding: 0">
            <div class="text-left pl-3">
              <template v-for="cause in groupByParentCause(nonConformity.root_cause)">
                <span class="font-weight-bold">{{ cause.name }}</span>
                <div class="pl-3" v-for="childCauses in cause.selected_causes">
                  <font-awesome-icon
                    style="font-size: 5px; margin-bottom: 2px"
                    icon="circle"/>
                  {{ childCauses.name }}
                </div>
              </template>
            </div>
          </td>
          <td class="align-middle"> {{ nonConformity.training_need === 1 ? 'YES' : 'NO' }} </td>
          <td class="align-middle"> {{ nonConformity.change_of_procedures === 1 ? 'YES' : 'NO' }} </td>
          <td class="align-middle">
            <div class="dropdown dropright">
              <button
                id="actionDropdown"
                aria-expanded="false"
                aria-haspopup="true"
                class="mr-2 btn btn-sm btn-secondary force-white-all"
                data-toggle="dropdown"
              >
                <font-awesome-icon icon="cogs"/>
              </button>
              <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" style="min-height: 50px">
                <button class="dropdown-item text-secondary font-weight-bolder"
                        @click="viewSelected(nonConformity.id, index)">
                  <font-awesome-icon icon="eye"/>
                  View
                </button>
                <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                        @click="deleteSelected(nonConformity.id)"
                >
                  <font-awesome-icon icon="trash" class="force-danger-all"/>
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <template v-if="$route.params.ncr_id !== 'index' && activeNcrIndex !== null">
      <company-non-conformity-detail
        :key="'non_conformity_' + $route.params.ncr_id + '_' + activeNcrIndex"
        :active-ncr-index="activeNcrIndex"
        :conformity-length="companyNonConformities[$route.params.type].length"
        @changeIndex="changeIndex"
        @close="closeDetail"
      >
      </company-non-conformity-detail>
    </template>

    <company-add-non-conformity
      :key="'add-ncr-' + showAddNcrModal"
      @close="showAddNcrModal = false"
    />
  </div>

</template>

<style scoped>

</style>

<script>
import CompanyAddNonConformity from "@/components/modules/ia/company/nonConformity/CompanyAddNonConformity.vue";
import CompanyNonConformityMixin from "@/mixins/CompanyNonConformityMixin";
import {AlertService} from "@/services/AlertService";
import {mapActions} from "vuex";
import {vueAppMixin} from "@/mixins/vueAppMixin";
import companyNonConformityDetail from "@/components/modules/ia/company/nonConformity/CompanyNonConformityDetail.vue";

  export default {
    name: 'CompanyNonConformity',
    components : {companyNonConformityDetail, CompanyAddNonConformity},
    mixins: [CompanyNonConformityMixin, vueAppMixin],
    data() {
      return {
        activeNcrIndex : null,
        showAddNcrModal : false,
        selectedNcrType : this.$route.params.ncr_type
      }
    },
    methods : {
      ...mapActions([
        'deleteCompanyNonconformity'
      ]),
      setNcrType() {
        this.$router.replace({name: this.$route.name, params: {
            ncr_type : this.selectedNcrType
          }});
      },
      changeIndex(newIndex) {
        this.activeNcrIndex = newIndex;
        this.$router.replace({name: this.$route.name, params: {
            ncr_id: this.companyNonConformities[this.selectedType][newIndex].id,
            oe_id  : 'index'
        }});
      },
      viewSelected(nonConformityId, activeNcrIndex) {
        this.activeNcrIndex = activeNcrIndex;

        if (nonConformityId !== this.$route.params.ncr_id) {
          this.$router.replace({name: 'CompanyNcrMainDetails', params: {
              ncr_id: nonConformityId,
              oe_id  : 'index'
          }});
        }
      },
      async deleteSelected(nonConformityId) {
        if(await AlertService.confirmDeleteAlert('Delete Non-conformity', 'Are you sure you want to delete this Non-conformity Record?', 'Delete')){
          swal.fire({
            title:'ENTER YOUR PASSWORD',
            input: 'password',
            inputAttributes: {
              autocapitalize: 'off'
            },
            confirmButtonText:'PROCEED DELETION',
            cancelButtonText: 'CANCEL',
            showCancelButton:true,
            allowOutsideClick:false,
            allowEscapeKey:false,
            allowEnterKey:false,
            confirmButtonColor:'#dc3545',
            showLoaderOnConfirm:true,
            preConfirm:async (inputValue)=> {
              let response = await this.deleteCompanyNonconformity({id: nonConformityId, password: inputValue});
              if (typeof response === 'boolean' && response) {
                return response;
              } else {
                if (response.hasOwnProperty('message') && !response.hasOwnProperty('errors')) {
                  swal.showValidationMessage(response.message);
                }
                else{
                  swal.showValidationMessage(response.errors.password.join(','));
                }
              }
            }
          })
            .then(async (result)=>{
              if(result.isConfirmed){
                await this.successAlert('NON-CONFORMITY DELETED SUCCESSFULLY','DELETE NON-CONFORMITY RECORD');
                await this.getUpdatedNonConformity();

                if (this.$route.name !== 'CompanyNonConformity') {
                  await this.$router.replace({name: 'CompanyNonConformity', params: {
                      ncr_id : 'index',
                      oe_id  : 'index'
                    }});
                }
              }
            });
        }
      },
      closeDetail() {
        this.activeNcrIndex = null;
        this.$router.replace({name: 'CompanyNonConformity', params: {
            ncr_id : 'index',
            oe_id  : 'index'
          }});
      }
    },
    async created() {
      const ncrIndex = this.companyNonConformities[this.selectedType].findIndex(ncr => ncr.id === parseInt(this.$route.params.ncr_id));
      if (ncrIndex !== -1) {
        this.activeNcrIndex = ncrIndex
      }
    },
    computed : {
      nonConformityByNcrType() {
        return this.companyNonConformities[this.$route.params.type].filter(ncr => ncr.ncr_type === this.$route.params.ncr_type);
      }
    }
  }
</script>

<style>
.no-padding-margin {
  padding: 0;
  margin: 0;
  list-style: none; /* Optional: remove bullet points */
}
</style>
